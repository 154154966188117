import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GameType } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';

import { GthGameTypeModel } from '../../models/game-type';
import { SrvCacheService } from '../cache.service';
import { CloudCollectionService } from './cloud-collection.service';

const COLLECTION_NAME = DBUtil.GameTypes;

@Injectable({
  providedIn: 'root',
})
export class SrvGameTypesService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
    private cache: SrvCacheService,
  ) {
  }

  /**
   * Gets a list of all game types
   * @return {Promise<GthGameTypeModel[]>} Returns a list of game types
   */
  async listAsync(): Promise<GthGameTypeModel[]> {
    const store = this.cache.read<GthGameTypeModel[]>(COLLECTION_NAME);
    if (store) return store;

    const items = await this.cloudCollection.list<GameType>();
    if (!items) return [];
    const models = items
      .map((i) => new GthGameTypeModel(i.id.toString(), i))
      .sort((a, b) => a.label.localeCompare(b.label));
    const otherIndex = models.findIndex((m) => m.label.toLowerCase() === 'other');
    if (otherIndex >= 0) {
      const removed = models.splice(otherIndex, 1);
      removed.forEach((r) => models.push(r));
    }

    this.cache.store(COLLECTION_NAME, models);
    return models;
  }

  async groupedAsync() {
    const gameTypes = await this.listAsync();
    const grouped: any = {};

    gameTypes.forEach((gameType) => {
      const type = gameType.type;
      if (!grouped[type]) {
        grouped[type] = [];
      }
      grouped[type].push(gameType);
    });

    return grouped;
  }
}

import { GameType } from '@index/interfaces';

import { GthModel } from './model';

export class GthGameTypeModel extends GthModel<GameType> {
  get gameType(): GameType {
    return this.model;
  }

  get uid() {
    return this.gameType.uid;
  }

  get id() {
    return this.gameType.id;
  }


  get icon() {
    return this.gameType.icon;
  }

  get imageURL() {
    return this.gameType.imageURL;
  }


  get label() {
    return this.gameType.label;
  }

  get item() {
    return this.gameType.item;
  }

  get type() {
    // TODO: Gametype ID 33 in DB has typ property instead of type
    return this.gameType.type ?? (this.gameType as any).typ;
  }

  get hasRoles() {
    return !!this.gameType.roles.length;
  }

  get roles() {
    return this.gameType.roles;
  }

  constructor(id: string, model: GameType) {
    super(id, model);
  }
}
